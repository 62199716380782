import React from "react";

const SubBanner = (props) => {
  return (
    <section
      class="generic-banner relative"
      style={{
        backgroundImage: `url('/sofa-bg.jpg')`,
        backgroundSize: "1500px",
        backgroundPositionY: "-400px",
      }}
    >
      <div class="overlay overlay-bg"></div>
      <div class="container">
        <div class="row height align-items-center justify-content-center">
          <div class="col-lg-10">
            <div class="banner-content text-center">
              <h2 style={{ color: "white" }}>{props.title}</h2>
              <p style={{ color: "white" }}>
                {props.subTitle ? props.subTitle : "some generic title"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubBanner;
