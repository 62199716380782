import React from "react";
import Vimeo from "@u-wave/react-vimeo";
const Video = () => {
  return (
    <section className="video-area pt-40 pb-40">
      <div className="overlay overlay-bg"></div>
      <div className="container">
        <div className="video-content">
          <Vimeo
            video="https://vimeo.com/126796661"
            autoplay
            responsive="true"
          />
          <div className="video-desc">
            <h3 className="h2 text-white text-uppercase">Woollen in video</h3>
            <h4 className="text-white">
              Just folks at woollen talking about their vision
            </h4>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Video;
