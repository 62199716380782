import React from "react";

const Feature = () => {
  return (
    <section className="feature-area pt-100 pb-100  relative">
      <div className="overlay overlay-bg"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-6 d-flex align-items-stretch">
            <div className="single-feature">
              <div className="icon">
                <span className="lnr lnr-laptop-phone"></span>
              </div>
              <div className="desc">
                <h2 className="text-uppercase">
                  3 Simple Ways To Save <br /> A Bunch Of Money
                </h2>
                <p>
                  Computer users and programmers have become so accustomed to
                  using Windows, even for the changing
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 d-flex align-items-stretch">
            <div className="single-feature">
              <div className="icon">
                <span className="lnr lnr-graduation-hat"></span>
              </div>
              <div className="desc">
                <h2 className="text-uppercase">
                  Baby Monitor <br />
                  Learning Technology
                </h2>
                <p>
                  While most people enjoy casino gambling, sports betting,
                  lottery and bingo playing for the fun and excitemen
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 d-flex align-items-stretch">
            <div className="single-feature">
              <div className="icon">
                <span className="lnr lnr-phone"></span>
              </div>
              <div className="desc">
                <h2 className="text-uppercase">
                  How Does An Lcd
                  <br />
                  Screen Work
                </h2>
                <p>
                  It is a good idea to think of your PC as an office. It stores
                  files, programs, pictures. This can be compared to an actual
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 d-flex align-items-stretch">
            <div className="single-feature">
              <div className="icon">
                <span className="lnr lnr-laptop"></span>
              </div>
              <div className="desc">
                <h2 className="text-uppercase">
                  The Skinny On Lcd <br />
                  Monitors
                </h2>
                <p>
                  Computers have become ubiquitous in almost every facet of our
                  lives. At work, desk jockeys spend hours in front of their
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 d-flex align-items-stretch">
            <div className="single-feature">
              <div className="icon">
                <span className="lnr lnr-heart"></span>
              </div>
              <div className="desc">
                <h2 className="text-uppercase">
                  For Women Only Your
                  <br />
                  Computer Usage
                </h2>
                <p>
                  About 64% of all on-line teens say that do things online that
                  they wouldn’t want their parents to know about. 11% of all
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 d-flex align-items-stretch">
            <div className="single-feature">
              <div className="icon">
                <span className="lnr lnr-screen"></span>
              </div>
              <div className="desc">
                <h2 className="text-uppercase">
                  5 Reasons To Purchase
                  <br /> Desktop Computers
                </h2>
                <p>
                  So you have your new digital camera and clicking away to glory
                  anything and everything in sight. Now you want to print
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Feature;
