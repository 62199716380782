import React from "react";
import "./linearicons.css";
// import "./owl.carousel.css";
import "./nice-select.css";
import "./magnific-popup.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./main.css";

import Header from "./components/header";
import Banner from "./components/banner";
import Video from "./components/video";
import About from "./components/about";
import Feature from "./components/feature";
import FAQ from "./components/faq";
import Contact from "./components/contact";
import Footer from "./components/footer";
import SubBanner from "./components/sub-banner";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ContentPage from "./components/content-page";

function App() {
  return (
    <Router>
      <div className="App">
        <div className="oz-body-wrap">
          <Switch>
            <Route exact path="/">
              <Header></Header>
              <Banner></Banner>
              <Video></Video>
              <About></About>
              <Feature></Feature>
              <FAQ></FAQ>
              <Contact></Contact>
              <Footer></Footer>
            </Route>
            <Route exact path="/cultivate-create-stimulate">
              <Header></Header>
              <SubBanner
                title="Cultivate, Create and Stimulate"
                subTitle="These are the strands that weave and bind together for a pattern of success!"
              ></SubBanner>
              <ContentPage title="How we Cultivate, Create and Stimulate those we serve...">
                <div class="col-lg-12">
                  <p>
                    A transformation that sticks: We know through experience and
                    leading research that 70-80% of Transformations/Change
                    Programs don't last or fail. We have vast experience in not
                    only helping organisations transform but doing it in a way
                    that sticks and is sustainable.
                  </p>
                </div>
                <div class="col-lg-12">
                  <p>
                    Talent Incubation: We focus on providing a strong capability
                    from day one and using this capability to create more
                    success and growth for your people. We create the space that
                    fosters learning and development and does it with a safe to
                    fail mindset. All of this backed up by a Fail-Safe
                    technology strategy creating the perfect place to learn and
                    grow talent.
                  </p>
                </div>
                <div class="col-lg-12">
                  <p>
                    Excite and Engage: For us as a team, we know what great
                    feels like and how it energises us and keeps us focused. We
                    focus on supporting the teams and companies we partner with
                    in creating a Vision of what this looks like. From this, we
                    then cultivate its growth until it becomes self-sufficient.
                    We create spaces that people want to get out of bed for.
                  </p>
                </div>
                <div class="col-lg-12">
                  <p>
                    We show up for the experience, not the paycheck: Unlike most
                    consultancies, we have seen, we as people are very
                    different. It's not about getting numbers through the door,
                    making more money or using your company as a learning
                    academy. Instead, we show up to create a powerful experience
                    for you and your people, unlocking the potential and success
                    that you desire.
                  </p>
                </div>
              </ContentPage>
            </Route>
            <Route exact path="/meet-the-team">
              <Header></Header>
              <SubBanner
                title="The Alchemy of a great team"
                subTitle="Like any recipe it’s the right balance of ingredients that helps things feel great. We focus on finding the right. ingredients to create that special team."
              ></SubBanner>
              <ContentPage>
                <h3 class="about-title mb-30">The CTO</h3>
                <div class="row">
                  <div class="col-md-3">
                    <img src="d.jpg" alt="" class="img-fluid" />
                  </div>
                  <div class="col-md-9 mt-sm-20">
                    <p>
                      Recently, the US Federal government banned online casinos
                      from operating in America by making it illegal to transfer
                      money to them through any US bank or payment system. As a
                      result of this law, most of the popular online casino
                      networks such as Party Gaming and PlayTech left the United
                      States. Overnight, online casino players found themselves
                      being chased by the Federal government. But, after a
                      fortnight, the online casino industry came up with a
                      solution and new online casinos started taking root. These
                      began to operate under a different business umbrella, and
                      by doing that, rendered the transfer of money to and from
                      them legal. A major part of this was enlisting electronic
                      banking systems that would accept this new clarification
                      and start doing business with me. Listed in this article
                      are the electronic banking systems that accept players
                      from the United States that wish to play in online
                      casinos.
                    </p>
                  </div>
                </div>
                <h3 class="about-title mb-30 mt-100">The Creative Prophet</h3>
                <div class="row">
                  <div class="col-md-3">
                    <img src="d.jpg" alt="" class="img-fluid" />
                  </div>
                  <div class="col-md-9 mt-sm-20">
                    <p>
                      Recently, the US Federal government banned online casinos
                      from operating in America by making it illegal to transfer
                      money to them through any US bank or payment system. As a
                      result of this law, most of the popular online casino
                      networks such as Party Gaming and PlayTech left the United
                      States. Overnight, online casino players found themselves
                      being chased by the Federal government. But, after a
                      fortnight, the online casino industry came up with a
                      solution and new online casinos started taking root. These
                      began to operate under a different business umbrella, and
                      by doing that, rendered the transfer of money to and from
                      them legal. A major part of this was enlisting electronic
                      banking systems that would accept this new clarification
                      and start doing business with me. Listed in this article
                      are the electronic banking systems that accept players
                      from the United States that wish to play in online
                      casinos.
                    </p>
                  </div>
                </div>
                <h3 class="about-title mb-30 mt-100">The Coach</h3>
                <div class="row">
                  <div class="col-md-3">
                    <img src="d.jpg" alt="" class="img-fluid" />
                  </div>
                  <div class="col-md-9 mt-sm-20">
                    <p>
                      Recently, the US Federal government banned online casinos
                      from operating in America by making it illegal to transfer
                      money to them through any US bank or payment system. As a
                      result of this law, most of the popular online casino
                      networks such as Party Gaming and PlayTech left the United
                      States. Overnight, online casino players found themselves
                      being chased by the Federal government. But, after a
                      fortnight, the online casino industry came up with a
                      solution and new online casinos started taking root. These
                      began to operate under a different business umbrella, and
                      by doing that, rendered the transfer of money to and from
                      them legal. A major part of this was enlisting electronic
                      banking systems that would accept this new clarification
                      and start doing business with me. Listed in this article
                      are the electronic banking systems that accept players
                      from the United States that wish to play in online
                      casinos.
                    </p>
                  </div>
                </div>
              </ContentPage>
            </Route>
            <Route exact path="/the-woollen-way">
              <Header></Header>
              <SubBanner title="The Woollen Way"></SubBanner>
              <ContentPage></ContentPage>
            </Route>
            <Route exact path="/how-we-can-work-with-you">
              <Header></Header>
              <SubBanner title="How can we work with you?"></SubBanner>
              <ContentPage></ContentPage>
            </Route>
            <Route exact path="/events/breaking-free-of-the-atmosphere">
              <Header></Header>
              <SubBanner title="Breaking Free of the Atomosphere"></SubBanner>
              <ContentPage></ContentPage>
            </Route>
            <Route exact path="/events/product-engineering-101">
              <Header></Header>
              <SubBanner title="Product Engineering 101"></SubBanner>
              <ContentPage></ContentPage>
            </Route>
            <Route exact path="/events/how-we-built-wthn-in-12-weeks">
              <Header></Header>
              <SubBanner title="How we built WTHN in 12 weeks"></SubBanner>
              <ContentPage></ContentPage>
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
