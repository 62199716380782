import React from "react";

const FAQ = () => {
  return (
    <section className="faq-area pt-100 pb-100">
      <div className="container">
        <div className="row">
          <div className="counter-left col-lg-3 col-md-3">
            <div className="single-facts">
              <h2 className="counter">5962</h2>
              <p>Projects Completed</p>
            </div>
            <div className="single-facts">
              <h2 className="counter">2394</h2>
              <p>New Projects</p>
            </div>
            <div className="single-facts">
              <h2 className="counter">1439</h2>
              <p>Tickets Submitted</p>
            </div>
            <div className="single-facts">
              <h2 className="counter">933</h2>
              <p>Cup of Coffee</p>
            </div>
          </div>
          <div className="faq-content col-lg-9 col-md-9">
            <div className="single-faq">
              <h2 className="text-uppercase">Are your Templates responsive?</h2>
              <p>
                “Few would argue that, despite the advancements of feminism over
                the past three decades, women still face a double standard when
                it comes to their behavior. While men’s borderline-inappropriate
                behavior.
              </p>
            </div>
            <div className="single-faq">
              <h2 className="text-uppercase">
                Does it have all the plugin as mentioned?
              </h2>
              <p>
                “Few would argue that, despite the advancements of feminism over
                the past three decades, women still face a double standard when
                it comes to their behavior. While men’s borderline-inappropriate
                behavior.
              </p>
            </div>
            <div className="single-faq">
              <h2 className="text-uppercase">
                Can i use the these theme for my client?
              </h2>
              <p>
                “Few would argue that, despite the advancements of feminism over
                the past three decades, women still face a double standard when
                it comes to their behavior. While men’s borderline-inappropriate
                behavior.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
