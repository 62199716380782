import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer className="section-gap">
      <div className="container">
        <div className="row pt-60">
          <div className="col-lg-3 col-sm-6">
            <div className="single-footer-widget">
              <h6 className="text-uppercase mb-20">About Woollen</h6>
              <ul className="footer-nav">
                <li>
                  <Link to="/cultivate-create-stimulate">Who are we?</Link>
                </li>
                <li>
                  <Link to="/meet-the-team">Meet the Team</Link>
                </li>
                <li>
                  <Link to="/the-woollen-way">The Woollen Way</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="single-footer-widget">
              <h6 className="text-uppercase mb-20">Navigation</h6>
              <ul className="footer-nav">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <a href="https://blog.woollen.io">Blog</a>
                </li>
                <li>
                  <Link to="/how-we-can-work-with-you">
                    How we can work with you
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="single-footer-widget">
              <h6 className="text-uppercase mb-20">Events</h6>
              <ul className="footer-nav">
                <li>
                  <Link to="/breaking-free-of-the-atomosphere">
                    Breaking free of the Atmosphere
                  </Link>
                </li>
                <li>
                  <Link to="/product-engineering-101">
                    Product Engineering 101
                  </Link>
                </li>
                <li>
                  <Link to="/how-we-built-wthn-in-12-weeks">
                    How we built WTHN in 12 weeks
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="single-footer-widget">
              <h6 className="text-uppercase mb-20">Quick About</h6>
              <p>
                Woollen is an independent london based consultancy who advise
                based on their own product engineering experience.
              </p>
              <p>
                +00 012 6325 98 6542 <br />
                hello@woollen.io
              </p>
              <div className="footer-social d-flex align-items-center">
                <a href="./">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a href="./">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="./">
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
                <a href="./">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom d-flex justify-content-between align-items-center flex-wrap">
          <p className="footer-text m-0">
            Copyright &copy; 2020 | Made in London with{" "}
            <FontAwesomeIcon icon={faHeart} /> by Woollen
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
