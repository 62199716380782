import React from "react";

const ContentPage = (props) => {
  return (
    <section class="about-generic-area">
      <div class="container border-top-generic">
        <h3
          style={{ display: props.title ? "true" : "none" }}
          class="about-title mb-70"
        >
          {props.title}
        </h3>
        <div class="row">{props.children || "Missing content"}</div>
      </div>
    </section>
  );
};

export default ContentPage;
