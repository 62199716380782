import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
const Banner = () => {
  return (
    <Carousel interval="4000">
      <Carousel.Item>
        <section className="banner-area relative">
          <div className="container">
            <div className="row fullscreen align-items-center justify-content-center">
              <div className="banner-left col-lg-6">
                <img
                  className="d-flex mx-auto img-fluid"
                  src="header-img.png"
                  alt=""
                />
              </div>
              <div className="col-lg-6">
                <div className="story-content">
                  <h6 className="text-uppercase">woollen</h6>
                  <h1>
                    Technology <span className="sp-1">Feeling</span>
                    <br />a little bit too <span className="sp-2">Dry</span>
                  </h1>
                  <Link
                    className="genric-btn primary circle arrow"
                    to="/are-you-sure-you-want-different"
                  >
                    Get Water<span className="lnr lnr-arrow-right"></span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Carousel.Item>
      <Carousel.Item>
        <section className="banner-area relative">
          <div className="container">
            <div className="row fullscreen align-items-center justify-content-center">
              <div className="banner-left col-lg-6">
                <img
                  className="d-flex mx-auto img-fluid"
                  src="rocket.png"
                  alt=""
                />
              </div>
              <div className="col-lg-6">
                <div className="story-content">
                  <h6 className="text-uppercase">woollen</h6>
                  <h1>
                    Struggling to <span className="sp-1">Break</span>
                    <br />
                    out of the <span className="sp-3">Atmosphere</span>
                  </h1>
                  <Link
                    className="genric-btn rocket circle arrow"
                    to="/are-you-sure-you-want-different"
                  >
                    Get Fuel<span className="lnr lnr-arrow-right"></span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Carousel.Item>
      <Carousel.Item>
        <section className="banner-area relative">
          <div className="container">
            <div className="row fullscreen align-items-center justify-content-center">
              <div className="banner-left col-lg-6">
                <img
                  className="d-flex mx-auto img-fluid"
                  src="dry-strawberry.png"
                  alt=""
                />
              </div>
              <div className="col-lg-6">
                <div className="story-content">
                  <h6 className="text-uppercase">woollen</h6>
                  <h1>
                    Is everything <span className="sp-1">Starting</span>
                    <br />
                    to feel a bit <span className="sp-4">Dry</span>
                  </h1>
                  <Link
                    className="genric-btn strawberry circle arrow"
                    to="/are-you-sure-you-want-different"
                  >
                    Get Hydration
                    <span className="lnr lnr-arrow-right"></span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Carousel.Item>
    </Carousel>
  );
};
export default Banner;
