import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className="default-header">
      <div className="container-fluid">
        <div className="header-wrap">
          <div className="header-top d-flex justify-content-between align-items-start">
            <div className="logo">
              <Link to="/">
                <img src="green-woollen.png" width="40%" alt="" />
              </Link>
            </div>
            <div className="main-menubar d-flex align-items-start">
              <nav
                className="hide"
                style={{ display: "flex", "flex-wrap:": "wrap" }}
              >
                <div style={{ flex: 1 }}>
                  <Link style={{ width: 230 }} to="/">
                    Home
                  </Link>
                  <hr />
                  <Link to="/cultivate-create-stimulate">
                    - Cultivate, Create and Stimulate!
                  </Link>
                  <Link to="/meet-the-team">- Meet the team</Link>
                  <Link to="/the-woollen-way">- The Woollen Way</Link>
                </div>
                <div style={{ flex: 1 }}></div>
                <div style={{ flex: 1 }}>
                  <Link to="/events" style={{ width: 230 }}>
                    Events
                  </Link>
                  <hr />
                  <Link to="/events/breaking-free-of-the-atmosphere">
                    - Breaking Free of the Atmosphere
                  </Link>
                  <Link to="/events/product-engineering-101">
                    - Product Engineering 101
                  </Link>
                  <Link to="/events/how-we-built-wthn-in-12-weeks">
                    - How we built WTHN in 12 weeks
                  </Link>
                </div>
                <div style={{ flex: 1, width: "10px" }}></div>
                <div style={{ flex: 1 }}>
                  <Link to="/contact">Get in touch</Link>
                  <hr />
                  <a href="https://blog.woollen.io"> - Blog</a>
                </div>
              </nav>
              <div className="menu-bar">
                <span className="lnr lnr-menu"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
