import React from "react";

const Contact = () => {
  return (
    <section className="contact-area pt-100 pb-100 relative">
      <div className="overlay overlay-bg"></div>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="single-contact col-lg-6 col-md-8">
            <h2 className="text-white">
              Send Us <span>Message</span>
            </h2>
            <p className="text-white">
              Most people who work in an office environment, buy computer
              products.
            </p>
          </div>
        </div>
        <form
          id="myForm"
          action="mail.php"
          method="post"
          className="contact-form"
        >
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <input
                name="fname"
                placeholder="Enter your name"
                className="common-input mt-20"
                required=""
                type="text"
              />
            </div>
            <div className="col-lg-5">
              <input
                name="email"
                placeholder="Enter email address"
                pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$"
                className="common-input mt-20"
                required=""
                type="email"
              />
            </div>
            <div className="col-lg-10">
              <textarea
                className="common-textarea mt-20"
                name="message"
                placeholder="Messege"
                required=""
              ></textarea>
            </div>
            <div className="col-lg-10 d-flex justify-content-end">
              <button className="primary-btn white-bg d-inline-flex align-items-center mt-20">
                <span className="mr-10">Send Message</span>
                <span className="lnr lnr-arrow-right"></span>
              </button>{" "}
              <br />
            </div>
            <div className="alert-msg"></div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Contact;
